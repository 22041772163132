import { useAccessToken } from 'hooks/use-access-token'
import React from 'react'
import { AuthService } from 'services/auth-service'
import { UserProfile } from 'types/dtos/auth-dtos'

interface AuthContextType {
  user: UserProfile | undefined
  isLoading?: boolean
}

const initialState: AuthContextType = {
  user: undefined,
  isLoading: true
}

export const AuthContext = React.createContext<AuthContextType>(initialState)

export const AuthProvider: React.FunctionComponent<React.PropsWithChildren> = ({
  children
}) => {
  const accessToken = useAccessToken()
  const [isLoading, setIsLoading] = React.useState(!!accessToken)
  const [user, setUser] = React.useState<UserProfile>()

  React.useEffect(() => {
    if (accessToken) {
      setIsLoading(true)
      AuthService.me()
        .then((response) => {
          setUser(response)
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }, [accessToken])

  return (
    <AuthContext.Provider
      value={{
        user,
        isLoading
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => {
  const context = React.useContext(AuthContext)

  if (!context) {
    throw new Error('useAuth must be used within a AuthProvider')
  }

  return context
}
