import React from 'react'

import { useConfig } from 'ui-library/contexts'
import { fetchColorReference } from 'utils/editor'
import { RowLabelType as Row } from '../../types/row'
import { Label } from '../label'

type Props = {
  row: Row
  selectLabel?: () => void
}

export const RowLabel: React.FunctionComponent<Props> = ({
  row,
  selectLabel
}) => {
  const { justify, label } = row

  const { brandInfo } = useConfig()

  const backgroundColorRef = row.theme?.backgroundColor

  const backgroundColor =
    backgroundColorRef &&
    fetchColorReference(backgroundColorRef, brandInfo.data.colors)

  return (
    <div
      className="flex-1 flex flex-col"
      style={{
        justifyContent: justify || 'start',
        backgroundColor
      }}
    >
      <div onClick={selectLabel}>
        <Label label={label} />
      </div>
    </div>
  )
}
