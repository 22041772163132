import { FieldType } from './field'

export enum DateType {
  PastTodayFuture = 'pastTodayFuture',
  Today = 'today',
  Past = 'past',
  PastToday = 'pastToday',
  Future = 'future',
  TodayFuture = 'todayFuture',
  Custom = 'custom'
}

export type FieldDate = {
  id: string
  name: string
  dateType: DateType
  excludeHolidays: boolean
  excludeWeekends: boolean
  excludedDates?: number[]
  required: boolean
  format?: string
  maxDate?: string
  readOnly?: boolean
  minDate?: string
  time?: boolean
  value?: string
  type: FieldType.Date
  theme: {
    style: {
      $ref: string
    }
  }
}

export type FieldDateEditType = {
  id: string
  dateType: DateType
  excluded?: string[]
  excludedDateNumbers: number[]
  readOnly?: boolean
  required: boolean
  format?: string
  maxDate?: string
  minDate?: string
  value?: string
  time?: boolean
  type: FieldType.Date
  theme: {
    style: {
      $ref: string
    }
  }
}
