import React from 'react'
import { fetchColorReference, fetchReference } from 'utils/editor'
import { conditionalClasses } from 'utils/theme'
import { useConfig } from '../contexts/config-context'
import { LabelType } from '../types'

type Props = {
  className?: string
  label: LabelType
  inherit?: boolean
}

export const Label: React.FunctionComponent<Props> = ({
  label: { localizedText: text, theme, hidden },
  className = ''
}) => {
  const { brandInfo } = useConfig()
  const { style, color } = theme

  const currentTheme = fetchReference(style, brandInfo.data.textStyles)
  const currentColor = fetchColorReference(color, brandInfo.data.colors)

  if (!text || !text?.length) {
    return null
  }

  return (
    <div
      className={conditionalClasses(
        className,
        currentTheme?.size,
        currentTheme?.weight,
        hidden && 'hidden'
      )}
      dangerouslySetInnerHTML={{
        __html: text
      }}
      style={{ color: currentColor }}
    />
  )
}
