import { HttpClient } from 'clients/http-client'
import { MeResponseDto } from 'types/dtos/auth-dtos'
import {
  ConfigChangeRequestDto,
  ConfigResponseDto
} from 'types/dtos/config-dtos'

export class ConfigurationService {
  static async getBrand() {
    return HttpClient.get<MeResponseDto>('/theme')
  }

  static async mfaReset() {
    return HttpClient.post('/me/mfa/reset')
  }

  static async getConfig() {
    return HttpClient.get<ConfigResponseDto>('/config')
  }

  static async changeConfig(data: ConfigChangeRequestDto) {
    return HttpClient.patch('/config', data)
  }

  static async logout(accessToken: string) {
    return HttpClient.post('/me/logout', undefined, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    })
  }
}
