import dayjs from 'dayjs'
import React from 'react'
import { useConfig } from 'ui-library/contexts'
import { fetchColorReference } from 'utils/editor'
import { RowHistoryType as Row } from '../../types/row'

type Props = {
  row: Row
  values: Record<string, string | string[]>
  saveHistory: (html: string) => void
}

const VARIABLE_REGEX = /{{\s*(.*?)\s*}}/g

export const RowHistory: React.FunctionComponent<Props> = ({
  row,
  values,
  saveHistory
}) => {
  const { history: html, hidden } = row

  const { brandInfo, locale } = useConfig()

  const backgroundColorRef = row.theme?.backgroundColor

  const backgroundColor =
    backgroundColorRef &&
    fetchColorReference(backgroundColorRef, brandInfo.data.colors)

  const replacemenetContext = {
    ...values
  }

  const renderedHtml = React.useMemo(() => {
    let renderedValue = html
    let match
    while ((match = VARIABLE_REGEX.exec(html.localizedText[locale]))) {
      const [key, format] = match[1].split(' | ')

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const [id, name]: any[] = key.split('.')

      renderedValue = renderedValue.localizedText[locale].replace(
        match[0],
        (format
          ? dayjs(replacemenetContext[id][name]).format(
              format.replace("format_date('", '').replace("')", '')
            )
          : replacemenetContext[id][name] || ''
        ).toString()
      )
    }

    return renderedValue
  }, [replacemenetContext])

  React.useEffect(() => {
    saveHistory(renderedHtml.localizedText[locale])
  }, [])

  if (hidden) {
    return null
  }

  return (
    <div
      style={{ backgroundColor }}
      dangerouslySetInnerHTML={{ __html: renderedHtml }}
    />
  )
}
