import React from 'react'

import { useConfig } from 'ui-library/contexts'
import { fetchColorReference } from 'utils/editor'
import { conditionalClasses } from 'utils/theme'
import { RowButtonsType as Row } from '../../types/row'

type Props = {
  row: Row
  leftButton?: React.ReactNode
  rightButton?: React.ReactNode
}

export const RowButtons: React.FunctionComponent<Props> = ({
  row,
  leftButton,
  rightButton
}) => {
  const { justify, button1, button2 } = row

  const { brandInfo } = useConfig()

  const backgroundColorRef = row.theme?.backgroundColor

  const backgroundColor =
    backgroundColorRef &&
    fetchColorReference(backgroundColorRef, brandInfo.data.colors)

  return (
    <div
      className="flex flex-row justify-end space-x-2"
      style={{
        justifyContent: justify || 'start',
        backgroundColor
      }}
    >
      <div className={conditionalClasses(button1.hidden && 'hidden')}>
        {leftButton}
      </div>
      <div className={conditionalClasses(button2.hidden && 'hidden')}>
        {rightButton}
      </div>
    </div>
  )
}
