import { v4 as uuidV4 } from 'uuid'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const generateNestedUuids = (object: any) => {
  if (typeof object !== 'object' || object === null) {
    return object
  }

  if (Array.isArray(object)) {
    return object.map((item) => generateNestedUuids(item))
  }

  const newObj = {}
  for (const [key, value] of Object.entries(object)) {
    if (key === 'id') {
      newObj[key] = uuidV4()
    } else {
      newObj[key] = generateNestedUuids(value)
    }
  }

  return newObj
}

interface JSONSchemaType {
  type: string
  title?: string
  description?: string
  properties?: Record<string, JSONSchemaType>
  items?: JSONSchemaType
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any
}

export interface FlattenedJSONSchema {
  [key: string]: Omit<JSONSchemaType, 'properties'>
}

export const flattenJsonSchema = (
  schema: JSONSchemaType
): FlattenedJSONSchema => {
  const result: FlattenedJSONSchema = {}

  function flatten(obj: JSONSchemaType, path: string = ''): void {
    const { properties, ...rest } = obj

    // Only add to result if it's not the root level
    if (path !== '') {
      result[path] = rest
    }

    // Flatten properties
    if (properties) {
      Object.entries(properties).forEach(([key, value]) => {
        const newPath = path ? `${path}.${key}` : key
        flatten(value, newPath)
      })
    }

    // Flatten items if it's an array type
    if (obj.type === 'array' && obj.items) {
      flatten(obj.items, `${path}[]`)
    }

    // Flatten additional properties for objects
    Object.entries(rest).forEach(([key, value]) => {
      if (
        typeof value === 'object' &&
        value !== null &&
        !Array.isArray(value) &&
        key !== 'items'
      ) {
        const newPath = path ? `${path}.${key}` : key
        flatten(value as JSONSchemaType, newPath)
      }
    })
  }

  flatten(schema)
  return result
}

export const fetchColorReference = (
  key: {
    $ref: string
    value?: string
  },
  source: Record<
    string,
    {
      value: string
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } & any
  >
) => {
  if (key?.value) {
    return key.value
  }

  return source[key?.$ref?.split('/')[2]]?.value
}

export const fetchReference = (
  key: {
    $ref: string
    value?: string
  },
  source: Record<
    string,
    {
      value: string
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } & any
  >
) => {
  if (key?.value) {
    return key.value
  }

  return source[key?.$ref?.split('/')[2]]
}
