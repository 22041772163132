import { useAccessToken } from 'hooks/use-access-token'
import { FunctionComponent, ReactNode, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { PATHS } from 'router/routes'

type GuestGuardProps = {
  children: ReactNode
}

export const GuestGuard: FunctionComponent<GuestGuardProps> = ({
  children
}) => {
  const accessToken = useAccessToken()
  const navigate = useNavigate()

  useEffect(() => {
    if (accessToken) {
      navigate(PATHS.DASHBOARD.INDEX)
    }
  }, [accessToken, navigate])

  return <>{children}</>
}
