import { ButtonEditType, ButtonItem } from './button'
import { FieldDate } from './date'
import { FieldAmount, FieldNumber, FieldText, GeneralFieldEdit } from './field'
import { FieldSelect } from './select'

export enum RowId {
  Label = 'label',
  ThreeBoxes = '3boxes',
  TwoBoxes = '2boxes',
  BoxesNew = 'boxesnew',
  Buttons = 'buttons',
  Field = 'field',
  Image = 'image',
  Separator = 'separator',
  History = 'history',
  Accordion = 'accordion',
  Checkbox = 'checkbox',
  Section = 'section'
}

export type LabelTheme = {
  style?: {
    $ref: string
  }
  color?: {
    $ref: string
  }
}

export type LabelType = {
  localizedText: string
  hidden?: boolean
  theme: LabelTheme
}

export type LabelEditType = {
  localizedText: Record<string, string>
  hidden?: boolean
  theme: LabelTheme
}

export type ImageType = {
  source: string
  alternateText?: string
}

export type ImageEditType = {
  source: string
  type: 'dataUri' | 'url'
  alternateText?: {
    localizedText: Record<string, string>
  }
}

export type RowLabelType = {
  id?: string
  layoutId: RowId.Label
  justify: 'start' | 'center' | 'end'
  label: LabelType

  theme?: {
    backgroundColor?: {
      $ref: string
    }
  }
}

export type RowLabelEditType = {
  id?: string
  layoutId: RowId.Label
  justify: 'start' | 'center' | 'end'
  label: LabelEditType

  theme?: {
    backgroundColor?: {
      $ref: string
    }
  }
}

export type RowHistoryType = {
  id?: string
  layoutId: RowId.History
  history: LabelType
  hidden: boolean

  theme?: {
    backgroundColor?: {
      $ref: string
    }
  }
}

export type RowHistoryEditType = {
  id?: string
  layoutId: RowId.History
  history: LabelEditType
  hidden: boolean

  theme?: {
    backgroundColor?: {
      $ref: string
    }
  }
}

export type RowThreeBoxesType = {
  id?: string
  layoutId: RowId.ThreeBoxes
  justify: 'start' | 'center' | 'end' | 'space-between'
  theme?: {
    backgroundColor?: {
      $ref: string
    }
  }

  box1Text?: LabelType
  box2Text?: LabelType
  box3Text?: LabelType
}

export type RowThreeBoxesEditType = {
  id?: string
  layoutId: RowId.ThreeBoxes
  justify: 'start' | 'center' | 'end' | 'space-between'
  theme?: {
    backgroundColor?: {
      $ref: string
    }
  }

  box1Text?: LabelEditType
  box2Text?: LabelEditType
  box3Text?: LabelEditType
}

export type RowTwoBoxesType = {
  id?: string
  layoutId: RowId.TwoBoxes
  justify: 'start' | 'center' | 'end' | 'space-between'
  theme?: {
    backgroundColor?: {
      $ref: string
    }
  }

  box1Text?: LabelType
  box2Text?: LabelType
}

export type RowTwoBoxesEditType = {
  id?: string
  layoutId: RowId.TwoBoxes
  justify: 'start' | 'center' | 'end' | 'space-between'
  theme?: {
    backgroundColor?: {
      $ref: string
    }
  }

  box1Text?: LabelEditType
  box2Text?: LabelEditType
}

export type RowBoxesNewEditType = {
  id?: string
  layoutId: RowId.BoxesNew
  justify: 'start' | 'center' | 'end' | 'space-between'
  theme?: {
    backgroundColor?: {
      $ref: string
    }
  }

  box1Text?: LabelEditType
  box2Text?: LabelEditType
  box3Text?: LabelEditType
}

export type RowButtonsType = {
  id?: string
  layoutId: RowId.Buttons
  justify:
    | 'start'
    | 'center'
    | 'end'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
  theme?: {
    backgroundColor?: {
      $ref: string
    }
  }

  button1?: ButtonItem
  button2?: ButtonItem
}

export type RowButtonsEditType = {
  id?: string
  layoutId: RowId.Buttons
  justify: 'start' | 'center' | 'end'
  theme?: {
    backgroundColor?: {
      $ref: string
    }
  }

  button1?: ButtonEditType
  button2?: ButtonEditType
}

export type RowFieldType = {
  id?: string
  justify:
    | 'start'
    | 'center'
    | 'end'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
  hidden: boolean
  layoutId: RowId.Field
  field: (FieldText | FieldNumber | FieldAmount | FieldSelect | FieldDate) & {
    hidden?: boolean
  }
  title?: LabelType
  description?: LabelType

  theme?: {
    backgroundColor?: {
      $ref: string
    }
  }
}

export type RowFieldEditType = {
  id?: string
  justify:
    | 'start'
    | 'center'
    | 'end'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
  hidden: boolean
  layoutId: RowId.Field
  field: GeneralFieldEdit
  title?: LabelEditType
  description?: LabelEditType

  theme?: {
    backgroundColor?: {
      $ref: string
    }
  }
}

export type FieldCheckboxType = {
  id: string
  required?: boolean
  value?: string
  reset?: boolean
  readOnly?: boolean
  hidden?: boolean
  type: 'checkbox'
}

export type RowCheckboxType = {
  id?: string
  justify:
    | 'start'
    | 'center'
    | 'end'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
  layoutId: RowId.Checkbox

  field: FieldCheckboxType
  title: LabelType
  description?: LabelType

  theme?: {
    backgroundColor?: {
      $ref: string
    }
  }
}

export type RowCheckboxEditType = {
  id?: string
  justify:
    | 'start'
    | 'center'
    | 'end'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
  layoutId: RowId.Checkbox

  title: LabelEditType
  description?: LabelEditType
  field: FieldCheckboxType

  theme?: {
    backgroundColor?: {
      $ref: string
    }
  }
}

export type RowAccordionType = {
  id?: string
  layoutId: RowId.Accordion
  title: LabelType
  description: LabelType

  theme?: {
    backgroundColor?: {
      $ref: string
    }
  }
}

export type RowAccordionEditType = {
  id?: string
  layoutId: RowId.Accordion
  title: LabelEditType
  description: LabelEditType

  theme?: {
    backgroundColor?: {
      $ref: string
    }
  }
}

export type RowImageType = {
  id?: string
  layoutId: RowId.Image
  image: ImageType

  theme?: {
    backgroundColor?: {
      $ref: string
    }
  }
}

export type RowImageEditType = {
  id?: string
  layoutId: RowId.Image
  image: ImageEditType

  theme?: {
    backgroundColor?: {
      $ref: string
    }
  }
}

export type LineType = {
  hidden?: boolean
  theme: {
    style: 'solid' | 'dotted' | 'dashed' | 'double'
    color?: {
      $ref: string
    }
    width?: number
  }
}

export type RowSeparatorType = {
  id?: string
  layoutId: RowId.Separator
  style?: Record<string, string>
  line: LineType
  theme?: {
    backgroundColor?: {
      $ref: string
    }
  }
}

export type RowSectionType = {
  id?: string
  layoutId: RowId.Section
  title: LabelType
  description: LabelType
  verticalLine: LineType
  horizontalLine: LineType
  justify: 'start' | 'center' | 'end'
  theme?: {
    backgroundColor?: {
      $ref: string
    }
  }
}

export type RowSectionEditType = {
  id?: string
  layoutId: RowId.Section
  title: LabelEditType
  description: LabelEditType
  verticalLine: LineType
  horizontalLine: LineType
  justify: 'start' | 'center' | 'end'
  theme?: {
    backgroundColor?: {
      $ref: string
    }
  }
}

export type RowSeparatorEditType = RowSeparatorType

export type RowEditType = {
  type?: string
  id?: string
  theme?: {
    backgroundColor?: {
      $ref: string
    }
  }
} & (
  | RowLabelEditType
  | RowThreeBoxesEditType
  | RowTwoBoxesEditType
  | RowButtonsEditType
  | RowFieldEditType
  | RowImageEditType
  | RowSeparatorEditType
  | RowHistoryEditType
  | RowAccordionEditType
  | RowCheckboxEditType
  | RowSectionEditType
)
