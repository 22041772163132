import React from 'react'

import { CommandGroup } from 'cmdk'
import { Button } from 'components/ui/button'
import {
  CommandDialog,
  CommandEmpty,
  CommandInput,
  CommandItem,
  CommandList
} from 'components/ui/command'
import { useEditor } from 'contexts/editor-context'
import { useConfig } from 'ui-library'

export const GlobalAutocompleteHelper: React.FunctionComponent = () => {
  const { externalFields } = useConfig()
  const { rowsController, selectedPropertyController } = useEditor()

  const [rows] = rowsController
  const [selectedProperty] = selectedPropertyController

  const inputRef = React.useRef<HTMLInputElement | HTMLTextAreaElement>(null)
  const commandInputRef = React.useRef<HTMLInputElement | null>(null)

  const [open, setOpen] = React.useState(false)

  React.useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (e.key === 'k' && (e.metaKey || e.ctrlKey)) {
        e.preventDefault()
        setOpen((open) => !open)
      }
    }

    document.addEventListener('keydown', down)
    return () => document.removeEventListener('keydown', down)
  }, [])

  const handleTargetChange = (event: InputEvent) => {
    const target = event.target as HTMLInputElement | HTMLTextAreaElement
    if (event.data === '{' && target.value.endsWith('{{')) {
      setOpen(true)
      inputRef.current = target
    }
  }

  const handleTargetBlur = (event: Event) => {
    const target = event.target as HTMLElement

    target.removeEventListener('change', handleTargetChange)
    target.removeEventListener('blur', handleTargetBlur)
  }

  const handleGlobalFocus = (event: FocusEvent) => {
    const target = event.target as HTMLElement

    target.addEventListener('input', handleTargetChange)

    target.addEventListener('blur', handleTargetBlur)
  }

  React.useEffect(() => {
    document.addEventListener('focus', handleGlobalFocus, true)

    return () => {
      document.removeEventListener('focus', handleGlobalFocus, true)
    }
  }, [])

  function setNativeValue(element, value) {
    const valueSetter = Object.getOwnPropertyDescriptor(element, 'value').set
    const prototype = Object.getPrototypeOf(element)
    const prototypeValueSetter = Object.getOwnPropertyDescriptor(
      prototype,
      'value'
    ).set

    if (valueSetter && valueSetter !== prototypeValueSetter) {
      prototypeValueSetter.call(element, value)
    } else {
      valueSetter.call(element, value)
    }
  }

  const handleCommandSelect = (command: string) => {
    const value = inputRef.current?.value.split(' ')

    value.push(value.pop().replace('{{', command))

    if (inputRef.current) {
      // https://github.com/facebook/react/issues/10135#issuecomment-314441175
      setNativeValue(inputRef.current, value.join(' '))
      inputRef.current.dispatchEvent(new Event('input', { bubbles: true }))
    }

    setOpen(false)
  }

  return (
    <CommandDialog open={open} onOpenChange={setOpen}>
      <CommandInput
        ref={commandInputRef}
        placeholder="Type a command or search..."
      />
      <CommandList>
        <CommandEmpty>
          <div className="px-2">
            <Button
              className="w-full"
              variant="ghost"
              onClick={() =>
                handleCommandSelect(`{{ ${commandInputRef.current.value} }}`)
              }
            >
              Insert
            </Button>
          </div>
        </CommandEmpty>
        <CommandGroup>
          {[
            ...Object.entries(externalFields).map(([key, value]) => ({
              key: `{{ ${key} }}`,
              type: `${value.type}${value.items?.type ? `(${value.items?.type})` : ''}`,
              description: value.description
            })),

            ...rows
              .filter((row) => row.layoutId === 'field')
              .filter((row) => row.id !== selectedProperty?.rowId)
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              .map(({ field }: any) => ({
                key: `{{ field.${field.id} }}`
              }))
          ].map(
            ({
              key,
              description,
              type
            }: {
              key: string
              description: string
              type: string
            }) => (
              <CommandItem key={key} onSelect={() => handleCommandSelect(key)}>
                <div className="w-full cursor-pointer">
                  <div className="flex justify-between">
                    <span>{key.replace('{{ ', '').replace(' }}', '')}</span>
                    <span className="text-muted-foreground">{type}</span>
                  </div>
                  <span className="text-muted-foreground">{description}</span>
                </div>
              </CommandItem>
            )
          )}
        </CommandGroup>
      </CommandList>
    </CommandDialog>
  )
}
