import { AuthGuard } from 'guards/auth-guard'
import { GuestGuard } from 'guards/guest-guard'

import React from 'react'

import { PermissionGuard } from 'guards/permission-guard'
import { createBrowserRouter, redirect, RouterProvider } from 'react-router-dom'
import { PATHS } from './routes'

const DashboardLayout = React.lazy(
  () => import('layouts/admin-panel/dashboard-layout')
)

const LoginPage = React.lazy(() => import('pages/sign-in-page'))
const ChangePaswordPage = React.lazy(() => import('pages/change-password-page'))
const MfaSetupPage = React.lazy(() => import('pages/mfa-setup-page'))

const MetricsPage = React.lazy(() => import('pages/metrics-page'))
const ConfigurationBrandPage = React.lazy(
  () => import('pages/configuration/configuration-brand-page')
)
const ConfigurationSystemPage = React.lazy(
  () => import('pages/configuration/configuration-system-page')
)
const ConfigurationThemePage = React.lazy(
  () => import('pages/configuration/configuration-theme-page')
)
const ConfigurationImportExportPage = React.lazy(
  () => import('pages/configuration/configuration-import-export-page')
)

const CustomersSearchPage = React.lazy(
  () => import('pages/customers-search-page')
)
const CustomerDetailsPage = React.lazy(
  () => import('pages/customer-details-page')
)

const UsersPage = React.lazy(() => import('pages/users-page'))

const ProfilePage = React.lazy(() => import('pages/profile'))
const SignOutPage = React.lazy(() => import('pages/sign-out-page'))

const JourneyListPage = React.lazy(() => import('pages/journey-list-page'))
const JourneyFlowPage = React.lazy(() => import('pages/journey-flow-page'))

export const router = createBrowserRouter([
  {
    path: PATHS.AUTH.SIGN_IN,
    element: (
      <GuestGuard>
        <LoginPage />
      </GuestGuard>
    )
  },
  {
    path: PATHS.AUTH.CHANGE_PASSWORD,
    element: (
      <GuestGuard>
        <ChangePaswordPage />
      </GuestGuard>
    )
  },
  {
    path: PATHS.AUTH.MFA_SETUP,
    element: (
      <GuestGuard>
        <MfaSetupPage />
      </GuestGuard>
    )
  },
  {
    path: PATHS.AUTH.SIGN_OUT,
    element: (
      <AuthGuard>
        <SignOutPage />
      </AuthGuard>
    )
  },
  {
    path: PATHS.DASHBOARD.INDEX,
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),

    children: [
      {
        index: true,
        loader: () => redirect(PATHS.DASHBOARD.HOME)
      },
      {
        path: 'home',
        element: <h1>Home</h1>
      },
      {
        path: PATHS.DASHBOARD.METRICS.INDEX,
        element: (
          <PermissionGuard requiredPermission="reporting">
            <MetricsPage />
          </PermissionGuard>
        )
      },
      {
        path: PATHS.DASHBOARD.CUSTOMERS.INDEX,
        children: [
          {
            index: true,
            loader: () => redirect(PATHS.DASHBOARD.CUSTOMERS.SEARCH)
          },
          {
            path: 'search',
            element: (
              <PermissionGuard requiredPermission="customers">
                <CustomersSearchPage />
              </PermissionGuard>
            )
          },
          {
            path: ':id',
            element: (
              <PermissionGuard requiredPermission="customers">
                <CustomerDetailsPage />
              </PermissionGuard>
            )
          }
        ]
      },
      {
        path: 'configuration',
        children: [
          {
            index: true,
            loader: () => redirect(PATHS.DASHBOARD.CONFIGURATION.BRAND)
          },
          {
            path: 'brand',
            element: (
              <PermissionGuard requiredPermission="configuration">
                <ConfigurationBrandPage />
              </PermissionGuard>
            )
          },
          {
            path: 'theme',
            element: (
              <PermissionGuard requiredPermission="configuration">
                <ConfigurationThemePage />
              </PermissionGuard>
            )
          },
          {
            path: 'system',
            element: (
              <PermissionGuard requiredPermission="configuration">
                <ConfigurationSystemPage />
              </PermissionGuard>
            )
          },
          {
            path: 'import-export',
            element: (
              <PermissionGuard requiredPermission="configuration">
                <ConfigurationImportExportPage />
              </PermissionGuard>
            )
          }
        ]
      },
      {
        path: PATHS.DASHBOARD.USERS.INDEX,
        element: (
          <PermissionGuard requiredPermission="users">
            <UsersPage />
          </PermissionGuard>
        )
      },

      {
        path: PATHS.DASHBOARD.JOURNEYS.INDEX,
        children: [
          {
            element: (
              <PermissionGuard requiredPermission="journeys">
                <JourneyListPage />
              </PermissionGuard>
            ),
            index: true
          },
          {
            path: PATHS.DASHBOARD.JOURNEYS.FLOW,
            element: (
              <PermissionGuard requiredPermission="journeys">
                <JourneyFlowPage />
              </PermissionGuard>
            )
          }
        ]
      },

      {
        path: PATHS.DASHBOARD.PROFILE.INDEX,
        element: <ProfilePage />
      }
    ]
  }
])

export const Router: React.FunctionComponent = () => (
  <React.Suspense fallback={<div>Loading...</div>}>
    <RouterProvider router={router} />
  </React.Suspense>
)
