import { useAuth } from 'contexts/auth-context'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { PATHS } from 'router/routes'
import { toast } from 'sonner'

interface PermissionGuardProps extends React.PropsWithChildren {
  requiredPermission: string
}

export const PermissionGuard: React.FunctionComponent<PermissionGuardProps> = ({
  requiredPermission,
  children
}) => {
  const { user } = useAuth()
  const navigate = useNavigate()

  useEffect(() => {
    if (user && !user?.permissions.includes(requiredPermission)) {
      navigate(PATHS.DASHBOARD.INDEX)
      toast.error('You do not have permission to access this page')
    }
  }, [user])

  return <>{children}</>
}
