import { useSyncExternalStore } from 'react'

// Constants
export const AUTH_EVENT_KEY = 'storage:auth'
export const ACCESS_TOKEN_LOCAL_STORAGE_KEY = 'ACCESS_TOKEN'
export const REFRESH_TOKEN_LOCAL_STORAGE_KEY = 'REFRESH_TOKEN'

// Utility Functions for Local Storage Operations
export const setAccessToken = (token: string) => {
  updateTokenInLocalStorage(ACCESS_TOKEN_LOCAL_STORAGE_KEY, token)
}

export const setRefreshToken = (token: string) => {
  updateTokenInLocalStorage(REFRESH_TOKEN_LOCAL_STORAGE_KEY, token)
}

export const deleteAccessToken = () => {
  removeTokenFromLocalStorage(ACCESS_TOKEN_LOCAL_STORAGE_KEY)
}

export const deleteRefreshToken = () => {
  removeTokenFromLocalStorage(REFRESH_TOKEN_LOCAL_STORAGE_KEY)
}

export const getAccessToken = () => {
  return localStorage.getItem(ACCESS_TOKEN_LOCAL_STORAGE_KEY)
}

export const getRefreshToken = () => {
  return localStorage.getItem(REFRESH_TOKEN_LOCAL_STORAGE_KEY)
}

// Helper Functions
const updateTokenInLocalStorage = (key: string, token: string) => {
  localStorage.setItem(key, token)
  dispatchAuthEvent(key, token)
}

const removeTokenFromLocalStorage = (key: string) => {
  localStorage.removeItem(key)
  dispatchAuthEvent(key, null)
}

const dispatchAuthEvent = (key: string, newValue: string | null) => {
  window.dispatchEvent(
    new StorageEvent(AUTH_EVENT_KEY, {
      key,
      newValue
    })
  )
}

// Snapshot and Subscription for useSyncExternalStore
const snapshot = getAccessToken

const subscribe = (listener: () => void) => {
  const handler = () => listener()
  window.addEventListener(AUTH_EVENT_KEY, handler)
  return () => window.removeEventListener(AUTH_EVENT_KEY, handler)
}

// Hook to Use Access Token with Sync External Store
export const useAccessToken = () => useSyncExternalStore(subscribe, snapshot)
