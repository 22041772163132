export const PATHS = {
  DASHBOARD: {
    INDEX: '/dashboard',
    HOME: '/dashboard/home',
    METRICS: {
      INDEX: '/dashboard/metrics',
      EXPORT_EVENTS: '/dashboard/metrics/export-events'
    },
    JOURNEYS: {
      INDEX: '/dashboard/journeys',
      NEW: '/dashboard/journeys/new',
      VIEW: '/dashboard/journeys/:id/version/:version',
      EDIT: '/dashboard/journeys/:id/version/:version/edit',
      FLOW: '/dashboard/journeys/:id/version/:version/flow'
    },
    CUSTOMERS: {
      INDEX: '/dashboard/customers',
      SEARCH: '/dashboard/customers/search',
      VIEW: '/dashboard/customers/:id',
      UPLOAD: '/dashboard/customers/upload'
    },
    USERS: {
      INDEX: '/dashboard/users',
      NEW: '/dashboard/users/new',
      VIEW: '/dashboard/users/:id',
      EDIT: '/dashboard/users/:id/edit'
    },
    CONFIGURATION: {
      INDEX: '/dashboard/configuration',
      BRAND: '/dashboard/configuration/brand',
      THEME: '/dashboard/configuration/theme',
      SYSTEM: '/dashboard/configuration/system',
      IMPORT_EXPORT: '/dashboard/configuration/import-export'
    },
    PROFILE: {
      INDEX: '/dashboard/profile'
    }
  },
  AUTH: {
    SIGN_IN: '/sign-in',
    CHANGE_PASSWORD: '/change-password',
    MFA_SETUP: '/mfa-setup',
    SIGN_OUT: '/sign-out'
  }
}

export const dynamicRoute = (path: string, params: Record<string, string>) =>
  Object.entries(params).reduce((acc, [key, value]) => {
    return acc.replace(`:${key}`, value)
  }, path)
