import { LabelEditType } from '../types'

const ADVANCED_TEXT_PREFIX = '{##}'
const ADVANCED_TEXT_SUFFIX = '{##}'

export const isAdvanced = (
  label: LabelEditType,
  locale: string,
  defaultLocale: string
) => {
  const text = label.localizedText[locale] || label.localizedText[defaultLocale]

  if (
    text.startsWith(ADVANCED_TEXT_PREFIX) &&
    text.endsWith(ADVANCED_TEXT_SUFFIX)
  ) {
    return true
  }

  return false
}

export const isLabelAdvanced = (localizedText: Record<string, string>) =>
  Object.values(localizedText).some(
    (value) =>
      value.startsWith(ADVANCED_TEXT_PREFIX) &&
      value.endsWith(ADVANCED_TEXT_SUFFIX)
  )

export const getSimplifiedLabel = (localizedText: Record<string, string>) =>
  Object.entries(localizedText).reduce(
    (accumulator, [key, value]) => ({
      ...accumulator,
      [key]: value
        .replace(ADVANCED_TEXT_PREFIX, '')
        .replace(ADVANCED_TEXT_SUFFIX, '')
    }),
    {}
  )

export const getSimplifiedText = (
  label: LabelEditType,
  locale: string,
  defaultLocale: string
) => {
  const text = label.localizedText[locale] || label.localizedText[defaultLocale]

  if (isAdvanced(label, locale, defaultLocale)) {
    return text
      .replace(ADVANCED_TEXT_PREFIX, '')
      .replace(ADVANCED_TEXT_SUFFIX, '')
  }

  return text
}

export const enhanceText = (text: Record<string, string>) => {
  return Object.entries(text).reduce(
    (accumulator, [key, value]) => ({
      ...accumulator,
      [key]: `${ADVANCED_TEXT_PREFIX}${value}${ADVANCED_TEXT_SUFFIX}`
    }),
    {}
  )
}
