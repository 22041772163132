import { createRoot } from 'react-dom/client'

import { App } from './App'

import reportWebVitals from './reportWebVitals'

import './css/tailwind.css'
import './theme/style.css'
import './index.css'

createRoot(document.getElementById('root')).render(<App />)

reportWebVitals()
