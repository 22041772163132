import { HttpClient } from 'clients/http-client'
import { BrandRequestDTO } from 'types/dtos/brand-dtos'

export class BrandService {
  static async getBrand() {
    return HttpClient.get<BrandRequestDTO>('/theme')
  }

  static async patchBrand(body: BrandRequestDTO) {
    return HttpClient.patch<BrandRequestDTO>('/theme', body)
  }
}
