export const getSeparators = (locale: string) => {
  const numberWithGroupAndDecimalSeparator = 1000.1

  const parts = Intl.NumberFormat(locale.replace('_', '-')).formatToParts(
    numberWithGroupAndDecimalSeparator
  )

  return {
    decimal: parts.find((part) => part.type === 'decimal')?.value as string,
    group: parts.find((part) => part.type === 'group')?.value as string
  }
}
