import { Toaster } from 'components/ui/sonner'
import React from 'react'
import { I18nextProvider } from 'react-i18next'
import { Slide, ToastContainer } from 'react-toastify'

import i18n from 'localization'
import { Router } from 'router'
import * as Yup from 'yup'

import { GlobalAutocompleteHelper } from 'components/global-autocomplete-helper'
import { AuthProvider } from 'contexts/auth-context'
import { CircleCheck, CircleX, Info, TriangleAlert } from 'lucide-react'
import 'react-toastify/dist/ReactToastify.css'
import { ConfigProvider } from 'ui-library'
import './css/dotFlashing.css'
import './css/passwordInput.css'
import './css/toastifyStyling.css'

Yup.setLocale({
  mixed: {
    required: 'Required'
  },
  string: {
    min: 'Minimum ${min} characters allowed',
    max: 'Maximum ${max} characters allowed'
  }
})

export const App: React.FunctionComponent = () => (
  <ConfigProvider
    languages={{
      en_GB: 'English',
      gr_GR: 'Greek'
    }}
    defaultLocale="en_GB"
    theme={{
      palette: {
        'ghost-white': '#f3f5f7',
        black: '#000000',
        white: '#ffffff',
        'blue-ribbon': '#004ecc',
        'midnight-blue': '#002f87',
        'pearl-bush': '#eeeeee',
        'silver-gray': '#d6d6d6',
        'tomato-red': '#eb5757'
      },
      buttons: {
        primary: {
          'background-color': '#7c38ed',
          'border-color': '#7c3aed',
          color: '#ffffff',
          'hover:background-color': '#7c38ed',
          'hover:color': '#ffffff',
          'font-size': '16px',
          'font-weight': '600',
          'box-shadow': 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
          'border-radius': '6px'
        },
        secondary: {
          'background-color': '#22dd22',
          'border-color': '#7c3aed',
          color: '#ffffff',
          'hover:background-color': '#22cd33',
          'hover:color': '#ffffff',
          'font-size': '16px',
          'font-weight': '600',
          'box-shadow': 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
          'border-radius': '6px'
        }
      },
      typography: {
        h1: {
          'font-size': '24px',
          'font-weight': '600',
          color: '#000000'
        },
        h2: {
          'font-size': '20px',
          'font-weight': '600',
          color: '#000000'
        },
        h3: {
          'font-size': '18px',
          'font-weight': '600',
          color: '#000000'
        },
        p: {
          'font-size': '14px',
          'font-weight': '400',
          color: '#000000'
        }
      },
      theme: {
        headerBackgroundColor: 'white',
        headerColor: 'black',
        footerColor: 'white',
        footerBackgroundColor: 'midnight-blue',
        containerColor: 'black',
        navigationColor: 'black',
        containerBackgroundColor: 'ghost-white',
        cardBackgroundColor: 'pearl-bush',
        cardColor: 'black',
        cardBorderColor: 'silver-gray',
        errorColor: 'tomato-red',
        inputBorderColor: 'silver-gray',
        inputAdditionsColor: 'black',
        inputColor: 'black',
        inputBackground: 'white',
        inputActiveBorderColor: 'blue-ribbon',
        radioSelectColor: 'black',
        radioSelectBackgroundColor: 'white',
        radioSelectActiveColor: 'white',
        radioSelectActiveBackgroundColor: 'blue-ribbon',
        buttonColor: '#ffffff',
        buttonBackgroundColor: '#004ecc',
        buttonHoverColor: '#004ecc',
        buttonHoverBackgroundColor: '#ffffff',
        buttonBorderColor: '#004ecc'
      },
      components: {
        card: {
          margin: '12px 0',
          padding: '16px 24px',
          shadow: '10px 12px 20px #ffffff',
          'border-radius': '6px'
        },
        row: {
          label: {
            'font-size': '18px',
            'font-weight': '600',
            margin: '0 12px 12px 0',
            color: '#000000',
            'font-style': 'normal'
          },
          description: {
            'font-size': '14px',
            'font-weight': '600',
            margin: '0 12px 12px 0'
          },
          button: {
            wrapper: {
              margin: '0 12px 12px 0',
              padding: '12px 28px'
            },
            button: {
              'font-size': '16px',
              margin: '0 12px 0',
              padding: '12px 28px',
              'border-radius': '6px'
            }
          },
          field: {
            label: {
              'font-size': '18px',
              margin: '0 0 4px 0',
              'font-weight': '600',
              color: '#000000'
            },
            input: {
              'border-radius': '6px',
              margin: '0 12px 12px 0'
            },
            description: {
              'font-size': '16px',
              margin: '0 0 8px 0',
              'font-weight': '400'
            }
          },
          boxes: {
            wrapper: {
              justify: 'end'
            },
            title: {
              padding: '0',
              'font-size': '16px',
              'font-weight': '600'
            },
            description: {
              padding: '0',
              'font-size': '14px',
              'font-weight': '400'
            },
            value: {
              padding: '0',
              'font-size': '133px',
              'font-weight': '400'
            }
          }
        }
      }
    }}
  >
    <I18nextProvider i18n={i18n}>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar
        closeOnClick={false}
        rtl={false}
        pauseOnHover
        transition={Slide}
        limit={1}
      />
      <AuthProvider>
        <Router />
      </AuthProvider>
      <GlobalAutocompleteHelper />
      <Toaster
        toastOptions={{
          classNames: {
            title: 'text-sm font-normal',
            success:
              'bg-green-50 text-green-600 space-x-2 border border-green-300 shadow-sm',
            error:
              'bg-red-50 text-red-600 space-x-2 border border-red-300 shadow-sm',
            info: 'bg-purple-50 text-purple-600 space-x-2 border border-purple-300 shadow-sm',
            warning:
              'bg-yellow-50 text-yellow-600 space-x-2 border border-yellow-300 shadow-sm'
          }
        }}
        icons={{
          success: <CircleCheck className="text-green-600" />,
          error: <CircleX className="text-red-600" />,
          info: <Info className="text-purple-600" />,
          warning: <TriangleAlert className="text-yellow-600" />
        }}
      />
    </I18nextProvider>
  </ConfigProvider>
)
