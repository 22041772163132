import React, { useMemo } from 'react'
import { Edge, Node, useEdgesState, useNodesState } from 'reactflow'
import { v4 as uuidV4 } from 'uuid'

import { SelectedProperty } from 'drawers/journey-flow-editor-drawers/properties-factory'

import {
  CardData,
  ExternalPageData,
  FlowType,
  InternalPageData
} from '../types/journey-flow-node'
import {
  FieldAmountEditType,
  FieldDateEditType,
  FieldNumberEditType,
  FieldSelectEditType,
  FieldTextEditType,
  RowEditType,
  RowFieldEditType,
  RowId,
  Url
} from '../ui-library/types'

export enum FlowMode {
  VIEW = 'view',
  EDIT = 'edit'
}

type Props = {
  children: React.ReactNode
  initialFlow: FlowType | null
  mode: FlowMode
}

type State = {
  pageNodes: Node<InternalPageData>[]
  externalPageNodes: Node<ExternalPageData>[]
  allPageNodes: Node<InternalPageData | ExternalPageData>[]
  cardNodes: Node<CardData>[]

  rowsController: ReturnType<typeof React.useState<RowEditType[]>>
  selectedCardController: ReturnType<typeof React.useState<Node<CardData>>>
  selectedPropertyController: ReturnType<
    typeof React.useState<SelectedProperty | undefined>
  >
  selectedLinkController: ReturnType<typeof React.useState<Edge<Url | unknown>>>

  nodesController: ReturnType<typeof useNodesState>
  edgesController: ReturnType<typeof useEdgesState>

  mode: FlowMode
  isEditable: boolean

  fields: (
    | FieldTextEditType
    | FieldAmountEditType
    | FieldDateEditType
    | FieldSelectEditType
    | FieldNumberEditType
  )[]
}

const initialState: State = {
  pageNodes: [],
  externalPageNodes: [],
  allPageNodes: [],
  cardNodes: [],

  selectedCardController: [undefined, () => {}],
  selectedPropertyController: [undefined, () => {}],
  selectedLinkController: [undefined, () => {}],

  rowsController: [[], () => {}],
  nodesController: [undefined, () => {}, () => {}],
  edgesController: [undefined, () => {}, () => {}],

  mode: FlowMode.VIEW,
  isEditable: false,

  fields: []
}

export const EditorContext = React.createContext(initialState)

export const EditorProvider: React.FunctionComponent<Props> = ({
  children,
  initialFlow,
  mode
}) => {
  const initialNode: Node<InternalPageData, 'page'> = {
    id: uuidV4(),
    type: 'page',
    position: { x: 30, y: 40 },

    style: {
      width: 350
    },

    data: {
      root: true,
      metadata: { name: 'Home page', description: 'Where the journey begins' },
      path: 'home',
      layoutWidth: 'full',
      header: null,
      layoutId: 'main'
    }
  }

  const edgesController = useEdgesState(initialFlow?.edges || [])
  const nodesController = useNodesState<
    InternalPageData | ExternalPageData | CardData
  >(initialFlow?.nodes || [initialNode])

  const rowsController = React.useState<RowEditType[]>([])
  const selectedCardController = React.useState<Node<CardData>>()
  const selectedPropertyController = React.useState<SelectedProperty>()
  const selectedLinkController = React.useState<Edge<Url | unknown>>()

  const [selectedProperty] = selectedPropertyController
  const [nodes] = nodesController
  const [rows] = rowsController
  const [selectedCard] = selectedCardController

  const isEditable = useMemo(() => mode === FlowMode.EDIT, [mode])

  const cardNodes = React.useMemo(
    () => nodes.filter((node) => node.type === 'card') as Node<CardData>[],
    [nodes]
  )

  const pageNodes = React.useMemo(
    () =>
      nodes.filter((node) => node.type === 'page') as Node<InternalPageData>[],
    [nodes]
  )

  const externalPageNodes = React.useMemo(
    () =>
      nodes.filter(
        (node) => node.type === 'externalPage'
      ) as Node<ExternalPageData>[],
    [nodes]
  )

  const fields = React.useMemo(
    () => [
      ...cardNodes
        .filter((card) => card.id !== selectedCard?.id)
        .reduce((accumulator, card) => {
          return [
            ...accumulator,
            ...card.data.rows
              .filter((row) => row.layoutId === RowId.Field)
              .filter((row) => !rows.map((r) => r.id).includes(row.id))
              .map((row: RowFieldEditType) => row.field)
              .filter(
                (field) =>
                  field.id !==
                  (selectedProperty?.property as FieldTextEditType)?.id
              )
          ]
        }, []),
      ...rows
        .filter((row) => row.layoutId === RowId.Field)
        .map((row: RowFieldEditType) => row.field)
        .filter(
          (field) =>
            field.id !== (selectedProperty?.property as FieldTextEditType)?.id
        )
    ],
    [cardNodes, rows, selectedCard, selectedProperty]
  )

  const allPageNodes = React.useMemo(
    () => [...pageNodes, ...externalPageNodes],
    [pageNodes, externalPageNodes]
  )

  return (
    <EditorContext.Provider
      value={{
        ...initialState,
        pageNodes,
        externalPageNodes,
        allPageNodes,
        cardNodes,
        rowsController,
        fields,

        selectedLinkController,
        selectedCardController,
        selectedPropertyController,

        nodesController,
        edgesController,

        mode,
        isEditable
      }}
    >
      {children}
    </EditorContext.Provider>
  )
}

export const useEditor = () => {
  const context = React.useContext(EditorContext)

  if (context === undefined) {
    throw new Error('useEditor must be used within a EditorProvider')
  }

  return context
}
