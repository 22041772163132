import i18n, { Resource } from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

import translationEN from './languages/en.json'

const fallbackLng = 'en'

const resources: Resource = {
  en: {
    translation: translationEN
  }
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng,
    interpolation: {
      escapeValue: false
    },
    resources
  })

export default i18n
