import React from 'react'

import { useConfig } from 'ui-library/contexts'
import { fetchColorReference } from 'utils/editor'
import { RowThreeBoxesType as Row } from '../../types/row'
import { Label } from '../label'

type Props = {
  row: Row
  selectBox1Text?: () => void
  selectBox2Text?: () => void
  selectBox3Text?: () => void
}

export const Row3Boxes: React.FunctionComponent<Props> = ({
  row,
  selectBox1Text,
  selectBox2Text,
  selectBox3Text
}) => {
  const { brandInfo } = useConfig()

  const backgroundColorRef = row.theme?.backgroundColor

  const backgroundColor =
    backgroundColorRef &&
    fetchColorReference(backgroundColorRef, brandInfo.data.colors)

  const { box1Text, box2Text, box3Text } = row

  const justify = row.justify

  return (
    <div
      className="flex flex-row"
      style={{
        justifyContent: justify || 'space-between',
        backgroundColor
      }}
    >
      <div className="flex-1 flex flex-col justify-between">
        <div onClick={selectBox1Text}>
          <Label label={box1Text} />
        </div>
      </div>
      <div className="flex-1 flex flex-col justify-between">
        <div onClick={selectBox2Text}>
          <Label label={box2Text} />
        </div>
      </div>

      <div className="flex-1 flex flex-col justify-between">
        <div onClick={selectBox3Text}>
          <Label label={box3Text} />
        </div>
      </div>
    </div>
  )
}
